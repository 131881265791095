export default [
  // menu check product: check sản phẩm đã có trong hệ thống chưa
  {
    path: '/check-product/list',
    name: 'check-product-list',
    component: () => import('@/views/product/list/CheckProductList.vue'),
    meta: {
      action: 'read',
      resource: 'CheckProduct',
      breadcrumb: [
        {
          text: 'Tất cả sản phẩm',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  // sản phẩm khách sạn & căn hộ dịch vụ
  {
    path: '/product-hotel/list',
    name: 'product-hotel-list',
    component: () => import('@/views/product-hotel/list/ListProduct.vue'),
    meta: {
      action: 'list',
      resource: 'ProductHotel',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hotel/manage',
    name: 'product-hotel-manage',
    component: () => import('@/views/product-hotel/list/ListProduct.vue'),
    meta: {
      action: 'read',
      resource: 'ProductHotelManage',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Khách sạn & CHDV',
          route: 'product-hotel-list',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hotel/create',
    name: 'product-hotel-create',
    component: () => import('@/views/product-hotel/create/CreateProduct.vue'),
    meta: {
      action: 'create',
      resource: 'ProductHotel',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Khách sạn & CHDV',
          route: 'product-hotel-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hotel/:id/edit',
    name: 'product-hotel-edit',
    component: () => import('@/views/product-hotel/edit/EditProduct.vue'),
    meta: {
      action: 'edit',
      resource: 'ProductHotel',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Khách sạn & CHDV',
          route: 'product-hotel-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hotel/:id/detail',
    name: 'product-hotel-detail',
    component: () => import('@/views/product-hotel/detail/DetailProduct.vue'),
    meta: {
      action: 'detail',
      resource: 'ProductHotel',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Khách sạn & CHDV',
          route: 'product-hotel-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  // sản phẩm thuê
  {
    path: '/product-hired/list',
    name: 'product-hired-list',
    component: () => import('@/views/product/list/ListProduct.vue'),
    meta: {
      action: 'list',
      resource: 'ProductHired',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hired/manage',
    name: 'product-hired-manage',
    component: () => import('@/views/product/list/ListProduct.vue'),
    meta: {
      action: 'read',
      resource: 'ProductHiredManage',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
          route: 'product-hired-list',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-expire/list',
    name: 'product-expire-list',
    component: () => import('@/views/product/expire/ExpireProduct.vue'),
    meta: {
      action: 'list',
      resource: 'ProductExpire',
      breadcrumb: [
        {
          text: 'BĐS sắp hết hạn',
        },
      ],
    },
  },
  {
    path: '/product-hired/create',
    name: 'product-hired-create',
    component: () => import('@/views/product/create/CreateProduct.vue'),
    meta: {
      action: 'create',
      resource: 'ProductHired',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
          route: 'product-hired-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hired/:id/edit',
    name: 'product-hired-edit',
    component: () => import('@/views/product/edit/EditProduct.vue'),
    meta: {
      action: 'edit',
      resource: 'ProductHired',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
          route: 'product-hired-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-hired/:id/detail',
    name: 'product-hired-detail',
    component: () => import('@/views/product/detail/DetailProduct.vue'),
    meta: {
      action: 'detail',
      resource: 'ProductHired',
      breadcrumb: [
        {
          text: 'Sản phẩm thuê',
          route: 'product-hired-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  // sản phẩm bán
  {
    path: '/product-sell/list',
    name: 'product-sell-list',
    component: () => import('@/views/product/list/ListProduct.vue'),
    meta: {
      action: 'list',
      resource: 'ProductSell',
      breadcrumb: [
        {
          text: 'Sản phẩm bán',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-sell/manage',
    name: 'product-sell-manage',
    component: () => import('@/views/product/list/ListProduct.vue'),
    meta: {
      action: 'read',
      resource: 'ProductSellManage',
      breadcrumb: [
        {
          text: 'Sản phẩm bán',
          route: 'product-sell-list',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-sell/create',
    name: 'product-sell-create',
    component: () => import('@/views/product/create/CreateProduct.vue'),
    meta: {
      action: 'create',
      resource: 'ProductSell',
      breadcrumb: [
        {
          text: 'Sản phẩm bán',
          route: 'product-sell-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-sell/:id/edit',
    name: 'product-sell-edit',
    component: () => import('@/views/product/edit/EditProduct.vue'),
    meta: {
      action: 'edit',
      resource: 'ProductSell',
      breadcrumb: [
        {
          text: 'Sản phẩm bán',
          route: 'product-sell-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-sell/:id/detail',
    name: 'product-sell-detail',
    component: () => import('@/views/product/detail/DetailProduct.vue'),
    meta: {
      action: 'detail',
      resource: 'ProductSell',
      breadcrumb: [
        {
          text: 'Sản phẩm bán',
          route: 'product-sell-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  // sản phẩm văn phòng
  {
    path: '/product-office/list',
    name: 'product-office-list',
    component: () => import('@/views/product-office/list/ListProductOffice.vue'),
    meta: {
      action: 'list',
      resource: 'ProductOffice',
      breadcrumb: [
        {
          text: 'Sản phẩm (VP)',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-office/manage',
    name: 'product-office-manage',
    component: () => import('@/views/product-office/list/ListProductOffice.vue'),
    meta: {
      action: 'read',
      resource: 'ProductOfficeManage',
      breadcrumb: [
        {
          text: 'Sản phẩm (VP)',
          route: 'product-office-list',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-office-expire/list',
    name: 'product-office-expire-list',
    component: () => import('@/views/product-office/expire/ExpireProductOffice.vue'),
    meta: {
      action: 'list',
      resource: 'ProductOfficeExpire',
      breadcrumb: [
        {
          text: 'Văn phòng sắp hết hạn',
        },
      ],
    },
  },
  {
    path: '/product-office/create',
    name: 'product-office-create',
    component: () => import('@/views/product-office/create/CreateProductOffice.vue'),
    meta: {
      action: 'create',
      resource: 'ProductOffice',
      breadcrumb: [
        {
          text: 'Sản phẩm (VP)',
          route: 'product-office-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-office/:id/edit',
    name: 'product-office-edit',
    component: () => import('@/views/product-office/edit/EditProductOffice.vue'),
    meta: {
      action: 'edit',
      resource: 'ProductOffice',
      breadcrumb: [
        {
          text: 'Sản phẩm (VP)',
          route: 'product-office-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product-office/:id/detail',
    name: 'product-office-detail',
    component: () => import('@/views/product-office/detail/DetailProductOffice.vue'),
    meta: {
      action: 'detail',
      resource: 'ProductOffice',
      breadcrumb: [
        {
          text: 'Sản phẩm (VP)',
          route: 'product-office-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
]
