export default [
  // khách hàng khách sạn & CHDV
  {
    path: '/customer-hotel/list',
    name: 'customer-hotel-list',
    component: () => import('@/views/customer-hotel/list/ListCustomer.vue'),
    meta: {
      action: 'list',
      resource: 'CustomerHotel',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-hotel/manage',
    name: 'customer-hotel-manage',
    component: () => import('@/views/customer-hotel/list/ListCustomer.vue'),
    meta: {
      action: 'read',
      resource: 'CustomerHotelManage',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-hotel/create',
    name: 'customer-hotel-create',
    component: () => import('@/views/customer-hotel/create/CreateCustomer.vue'),
    meta: {
      action: 'create',
      resource: 'CustomerHotel',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'Tạo mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-hotel/:id/edit',
    name: 'customer-hotel-edit',
    component: () => import('@/views/customer-hotel/edit/EditCustomer.vue'),
    meta: {
      action: 'edit',
      resource: 'CustomerHotel',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-hotel/:id/detail',
    name: 'customer-hotel-detail',
    component: () => import('@/views/customer-hotel/detail/DetailCustomer.vue'),
    meta: {
      action: 'detail',
      resource: 'CustomerHotel',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Khách sạn & CHDV',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  // khách hàng nhà phố
  {
    path: '/customer-corner/list',
    name: 'customer-corner-list',
    component: () => import('@/views/customer/list/ListCustomer.vue'),
    meta: {
      action: 'list',
      resource: 'CustomerCorner',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-corner/manage',
    name: 'customer-corner-manage',
    component: () => import('@/views/customer/list/ListCustomer.vue'),
    meta: {
      action: 'read',
      resource: 'CustomerCornerManage',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-corner/create',
    name: 'customer-corner-create',
    component: () => import('@/views/customer/create/CreateCustomer.vue'),
    meta: {
      action: 'create',
      resource: 'CustomerCorner',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Tạo mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-corner/:id/edit',
    name: 'customer-corner-edit',
    component: () => import('@/views/customer/edit/EditCustomer.vue'),
    meta: {
      action: 'edit',
      resource: 'CustomerCorner',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-corner/:id/detail',
    name: 'customer-corner-detail',
    component: () => import('@/views/customer/detail/DetailCustomer.vue'),
    meta: {
      action: 'detail',
      resource: 'CustomerCorner',
      breadcrumb: [
        {
          text: 'Khách hàng',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  // khách hàng văn phòng
  {
    path: '/customer-office/list',
    name: 'customer-office-list',
    component: () => import('@/views/customer-office/list/ListCustomerOffice.vue'),
    meta: {
      action: 'list',
      resource: 'CustomerOffice',
      breadcrumb: [
        {
          text: 'Khách hàng (VP)',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-office/manage',
    name: 'customer-office-manage',
    component: () => import('@/views/customer-office/list/ListCustomerOffice.vue'),
    meta: {
      action: 'read',
      resource: 'CustomerOfficeManage',
      breadcrumb: [
        {
          text: 'Khách hàng (VP)',
        },
        {
          text: 'Quản lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-office/create',
    name: 'customer-office-create',
    component: () => import('@/views/customer-office/create/CreateCustomerOffice.vue'),
    meta: {
      action: 'create',
      resource: 'CustomerOffice',
      breadcrumb: [
        {
          text: 'Khách hàng (VP)',
        },
        {
          text: 'Tạo mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-office/:id/edit',
    name: 'customer-office-edit',
    component: () => import('@/views/customer-office/edit/EditCustomerOffice.vue'),
    meta: {
      action: 'edit',
      resource: 'CustomerOffice',
      breadcrumb: [
        {
          text: 'Khách hàng (VP)',
        },
        {
          text: 'chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-office/:id/detail',
    name: 'customer-office-detail',
    component: () => import('@/views/customer-office/detail/DetailCustomerOffice.vue'),
    meta: {
      action: 'detail',
      resource: 'CustomerOffice',
      breadcrumb: [
        {
          text: 'Khách hàng (VP)',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
]
