export default [
  {
    path: '/report-list',
    name: 'report-list',
    component: () => import('@/views/report/list/ReportList.vue'),
    meta: {
      action: 'list',
      resource: 'Report',
      breadcrumb: [
        {
          text: 'Báo cáo nhân viên',
        },
      ],
    },
  },
  {
    path: '/report-general',
    name: 'report-general',
    component: () => import('@/views/report/general/ReportGeneral.vue'),
    meta: {
      action: 'list',
      resource: 'ReportGeneral',
      breadcrumb: [
        {
          text: 'Báo cáo tổng hợp',
        },
      ],
    },
  },
  {
    path: '/report-general/:id',
    name: 'report-general-detail',
    component: () => import('@/views/report/general/DetailReportGeneral.vue'),
    meta: {
      action: 'list',
      resource: 'ReportGeneral',
      breadcrumb: [
        {
          text: 'Báo cáo khách hàng',
        },
      ],
    },
  },
  {
    path: '/report-client',
    name: 'report-client',
    component: () => import('@/views/report/client/ReportClient.vue'),
    meta: {
      action: 'list',
      resource: 'ReportGeneral',
      breadcrumb: [
        {
          text: 'Báo cáo khách hàng',
        },
      ],
    },
  },
  {
    path: '/report-client/:id',
    name: 'report-client-detail',
    component: () => import('@/views/report/client/DetailReportClient.vue'),
    meta: {
      action: 'list',
      resource: 'ReportGeneral',
      breadcrumb: [
        {
          text: 'Báo cáo khách hàng',
        },
      ],
    },
  },
  {
    path: '/report-overview/',
    name: 'report-overview',
    component: () => import('@/views/report/overview/ReportOverview.vue'),
    meta: {
      action: 'list',
      resource: 'ReportOverview',
      breadcrumb: [
        {
          text: 'Báo cáo tổng quan',
        },
      ],
    },
  },

]
