import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import careers from '@/router/routes/careers'
import overview from './routes/overview'
import notification from './routes/notification'
import loginRequest from './routes/login-request'
import product from './routes/product'
import campaign from './routes/campaign'
import customer from './routes/customer'
import user from './routes/user'
import reports from './routes/report'
import settings from './routes/settings'

import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'overview' },
    },
    {
      path: '/logout',
      name: 'logout',
      component: {
        beforeRouteEnter(to, from, next) {
          localStorage.clear()
          next({ name: 'auth-login' })
        },
      },
    },
    ...overview,
    ...notification,
    ...loginRequest,
    ...customer,
    ...campaign,
    ...product,
    ...user,
    ...careers,
    ...reports,
    ...settings,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // return next({ name: 'misc-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    store.commit('app/setUserData', userData)
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
