export default [
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/user/list/UserList.vue'),
    meta: {
      action: 'list',
      resource: 'User',
      breadcrumb: [
        {
          text: 'Nhân viên',
        },
        {
          text: 'Danh sách',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id/detail',
    name: 'user-detail',
    component: () => import('@/views/user/detail/UserDetail.vue'),
    meta: {
      action: 'detail',
      resource: 'User',
      breadcrumb: [
        {
          text: 'Nhân viên',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/me',
    name: 'account',
    component: () => import('@/views/user/me/UserMe.vue'),
    meta: {
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Nhân viên',
        },
        {
          text: 'Chi tiết cá nhân',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: () => import('@/views/user/detail/UserDetail.vue'),
    meta: {
      action: 'create',
      resource: 'User',
      breadcrumb: [
        {
          text: 'Nhân viên',
        },
        {
          text: 'Tạo mới',
          active: true,
        },
      ],
    },
  },
]
