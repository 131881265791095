export default [
  {
    path: '/notification/list',
    name: 'notification-list',
    component: () => import('@/views/notification/Notification.vue'),
    meta: {
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Thông báo',
        },
        {
          text: 'Danh sách',
          active: true,
        },
      ],
    },
  },
]
