import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

console.log('appConfig', appConfig)
console.log('app', app)
console.log('verticalMenu', verticalMenu)

export default new Vuex.Store({
  modules: {
    // default
    appConfig,
    verticalMenu,
    // global data
    app,
  },
  strict: process.env.DEV,
})
