export default [
  {
    path: '/settings/users',
    name: 'settings-users',
    component: () => import('@/views/settings/users/list/UsersList.vue'),
    meta: {
      action: 'read',
      resource: 'Settings',
      breadcrumb: [
        {
          text: 'Thiết lập',
        },
      ],
    },
  },
  {
    path: '/settings/parameters',
    name: 'settings-parameters',
    component: () => import('@/views/settings/parameters/Parameters.vue'),
    meta: {
      action: 'read',
      resource: 'Settings',
      breadcrumb: [
        {
          text: 'Thiết lập',
          to: '/settings/users',
        },
        {
          text: 'Thông số',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/roles',
    name: 'settings-roles',
    component: () => import('@/views/settings/roles/list/RolesList.vue'),
    meta: {
      action: 'read',
      resource: 'Settings',
      breadcrumb: [
        {
          text: 'Thiết lập',
          to: '/settings/users',
        },
        {
          text: 'Phân quyền',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/roles/create',
    name: 'settings-roles-create',
    component: () => import('@/views/settings/roles/list/RolesDetail.vue'),
    props: {
      pageMode: 'create',
    },
    meta: {
      action: 'read',
      resource: 'Settings',
      breadcrumb: [
        {
          text: 'Thiết lập',
          to: '/settings/users',
        },
        {
          text: 'Phân quyền',
          to: '/settings/roles',
        },
        {
          text: 'Tạo phân quyền',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/roles/edit/:id',
    name: 'settings-roles-edit',
    component: () => import('@/views/settings/roles/list/RolesDetail.vue'),
    props: {
      pageMode: 'edit',
    },
    meta: {
      action: 'read',
      resource: 'Settings',
      breadcrumb: [
        {
          text: 'Thiết lập',
          to: '/settings/users',
        },
        {
          text: 'Phân quyền',
          to: '/settings/roles',
        },
        {
          text: 'Chỉnh sửa phân quyền',
          active: true,
        },
      ],
    },
  },
]
