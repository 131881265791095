export default [
  {
    path: '/login-request',
    name: 'login-request',
    component: () => import('@/views/login-request/LoginRequest.vue'),
    meta: {
      resource: 'LoginRequest',
      breadcrumb: [
        {
          text: 'Yêu cầu truy cập',
        },
        {
          text: 'Danh sách',
          active: true,
        },
      ],
    },
  },
]
