export default [
  {
    path: '/career/list',
    name: 'career-list',
    component: () => import('@/views/career/list/CareerList.vue'),
    meta: {
      action: 'list',
      resource: 'Career',
      breadcrumb: [
        {
          text: 'Ngành nghề',
        },
        {
          text: 'Danh sách',
          active: true,
        },
      ],
    },
  },
]
