export function getPlatform() {
  const { userAgent, platform } = window.navigator
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os
  if (iosPlatforms.includes(platform)
        // For new IPads with M1 chip and IPadOS platform returns "MacIntel"
        || (platform === 'MacIntel' && ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 2))) {
    os = 'iOS'
  } else if (macosPlatforms.includes(platform)) {
    os = 'macOS'
  } else if (windowsPlatforms.includes(platform)) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}
export const PLATFORM_ENV = getPlatform()
export const IS_IOS = PLATFORM_ENV === 'iOS'
export const IS_TOUCH_ENV = window.matchMedia('(pointer: coarse)').matches
