export default [
  // sản phẩm thuê
  {
    path: '/campaign/list',
    name: 'campaign-list',
    component: () => import('@/views/campaign/list/ListCampaign.vue'),
    meta: {
      action: 'list',
      resource: 'Campaign',
      breadcrumb: [
        {
          text: 'Chiến dịch (thuê)',
        },
        {
          text: 'Tìm kiếm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign/create',
    name: 'campaign-create',
    component: () => import('@/views/campaign/create/CreateCampaign.vue'),
    meta: {
      action: 'create',
      resource: 'Campaign',
      breadcrumb: [
        {
          text: 'Chiến dịch (thuê)',
          route: 'campaign-list',
        },
        {
          text: 'Tạo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign/:id/edit',
    name: 'campaign-edit',
    component: () => import('@/views/campaign/edit/EditCampaign.vue'),
    meta: {
      action: 'edit',
      resource: 'Campaign',
      breadcrumb: [
        {
          text: 'Chiến dịch (thuê)',
          route: 'campaign-list',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign/:id/detail',
    name: 'campaign-detail',
    component: () => import('@/views/campaign/detail/DetailCampaign.vue'),
    meta: {
      action: 'detail',
      resource: 'Campaign',
      breadcrumb: [
        {
          text: 'Chiến dịch (thuê)',
          route: 'campaign-list',
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
    },
  },
]
