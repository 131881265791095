import Vue from 'vue'
import axios from 'axios'
import util from '@/global/util'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-absolute-path,import/no-unresolved
import * as pkg from '/package.json'

const instant = axios.create({
  // You can add your headers here
  // ================================
  baseURL: $themeConfig.app.host,
  withCredentials: false,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const axiosIns = { ...instant }

axios.interceptors.response.use(
  resp => {
    const feVersion = resp.headers['fe-version'] || pkg.version
    if (feVersion !== localStorage.getItem('fe-version') && resp.config.method === 'get') {
      localStorage.setItem('fe-version', feVersion)
      window.location.reload() // For new version, simply reload on any get
    }
    return Promise.resolve(resp)
  },
)

const methods = ['get', 'post', 'put', 'delete']

methods.forEach(item => {
  axiosIns[item] = (url, data) => instant[item](url, util.deleteEmptyField(data))
})

Vue.prototype.$http = axiosIns

export default axiosIns
